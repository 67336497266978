import React, { createContext, useEffect, useState } from 'react';
import {
  GetSelfQuery,
  useGetSelfQuery,
  useRegisterPushSubscriptionMutation,
} from '../graphql-operations';
import { App } from 'antd';

interface UserContextType {
  user: GetSelfQuery['getSelf'];
  loading: boolean;
}

const initialState: UserContextType = {
  user: null,
  loading: true,
};

export const UserContext = createContext<UserContextType>(initialState);

interface UserContextProps {
  children: React.ReactElement;
}

const UserContextProvider = (props: UserContextProps) => {
  const { notification } = App.useApp();
  const { loading, data } = useGetSelfQuery();
  const [askedForPermission, setAskedForPermission] = useState(false);

  const [registerPushSubscription] = useRegisterPushSubscriptionMutation({
    onError: notification.error,
    onCompleted: () => notification.success({ message: 'Subscribed to push' }),
  });

  const askForPermission = async () => {
    const permission = await Notification.requestPermission();
    console.log('PERMISION', permission);
    if (permission === 'granted') {
      const registration = await navigator.serviceWorker.ready;
      const existingSub = await registration.pushManager.getSubscription();
      console.log('EXISTING SUB', existingSub);
      if (!existingSub) {
        const subscribeOptions = {
          applicationServerKey:
            'BLnmDMfXaBzoTaKhZEAT9vHLMC_DyWFNbo8W96TtLfh1yKDDPQy7rOQyc4B8UfR1XmZ3VD2xEHYfA2K1Q_SCVIs',
          userVisibleOnly: true,
        };
        const subscription = (
          await registration.pushManager.subscribe(subscribeOptions)
        ).toJSON();
        console.log('SUBSCRIPTOPN', subscription);
        if (subscription.keys && subscription.endpoint) {
          registerPushSubscription({
            variables: {
              input: {
                endpoint: subscription.endpoint,
                p256dh: subscription.keys.p256dh,
                auth: subscription.keys.auth,
              },
            },
          });
        }
      } else {
        const subscription = existingSub.toJSON();
        if (subscription.keys && subscription.endpoint) {
          registerPushSubscription({
            variables: {
              input: {
                endpoint: subscription.endpoint,
                p256dh: subscription.keys.p256dh,
                auth: subscription.keys.auth,
              },
            },
          });
        }
      }
    }
    setAskedForPermission(true);
  };

  useEffect(() => {
    if (data?.getSelf?.id && !askedForPermission) {
      askForPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, askedForPermission]);

  const value: UserContextType = {
    user: data?.getSelf,
    loading,
  };

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};

export default UserContextProvider;
