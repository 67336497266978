import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { Calendar as AntdCalendar, CalendarProps as AntdCalendarProps, Spin } from 'antd';
import React, { useState } from 'react';
import {
  MeasurementType,
  Statistics_Unit,
  useGetStatisticsQuery,
} from '../../graphql-operations';
import { useParams } from 'react-router-dom';
import Statistics from './statistics';
import { CalendarMode } from 'antd/es/calendar/generateCalendar';

const Calendar = () => {
  const [selectedDate, setSelectedDate] = useState(() => dayjs());
  const [mode, setMode] = useState<CalendarMode>('month');
  const params = useParams();

  const { loading, data } = useGetStatisticsQuery({
    variables: {
      locationSlug: params.locationSlug as string,
      after: selectedDate.startOf(mode).toISOString(),
      before: selectedDate.endOf(mode).toISOString(),
      type: MeasurementType.Temperature,
      unit: mode === 'month' ? Statistics_Unit.Day : Statistics_Unit.Month,
    },
  });

  const calendarProps: AntdCalendarProps<Dayjs> = {
    value: selectedDate,
    mode,
    onSelect: (newValue) => {
      setSelectedDate(newValue);
    },
    onPanelChange: (date, mode) => {
      setSelectedDate(date);
      setMode(mode);
    },
    cellRender(date) {
      if (!data) return null;
      const stats = data.getStatistics.find((statistic) => {
        return date.isSame(statistic.date, mode === 'month' ? 'day' : 'month');
      });
      if (!stats) return null;
      return <Statistics stats={stats} />;
    },
  };

  const Wrapper = loading ? Spin : React.Fragment;

  return (
    <Wrapper>
      <AntdCalendar {...calendarProps} />;
    </Wrapper>
  );
};

export default Calendar;
