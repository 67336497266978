import { Tooltip, Typography } from 'antd';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

export interface RelativeTimestampProps {
  timestamp: string;
}

const RelativeTimestamp = (props: RelativeTimestampProps) => {
  const dateTime = DateTime.fromISO(props.timestamp);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setKey((key) => key + 1);
    }, 10 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!dateTime.isValid) {
    return <Typography.Text type="secondary">Invalid timestamp</Typography.Text>;
  }

  return (
    <Tooltip
      title={DateTime.fromISO(props.timestamp).toLocaleString(
        DateTime.DATETIME_SHORT_WITH_SECONDS
      )}
    >
      <Typography.Text type="secondary" key={`${props.timestamp}-${key}`}>
        {dateTime.toRelative()}
      </Typography.Text>
    </Tooltip>
  );
};

export default RelativeTimestamp;
