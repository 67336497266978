import { App, Card, CardProps, Col, Row, Statistic } from 'antd';
import {
  MeasurementType,
  useGetMostRecentMeasurementQuery,
} from '../../graphql-operations';
import RelativeTimestamp from '../../components/relative-timestamp';
import { Link, useParams } from 'react-router-dom';

export interface LocationCardProps {
  slug: string;
  title: string;
}

const LocationCard = (props: LocationCardProps) => {
  const params = useParams();
  const cardProps: CardProps = {
    title: <Link to={`/homes/${params.slug}/${props.slug}`}>{props.title}</Link>,
  };

  const { notification } = App.useApp();

  const temperatureQuery = useGetMostRecentMeasurementQuery({
    variables: {
      locationSlug: props.slug,
      type: MeasurementType.Temperature,
    },
    onError: notification.error,
    pollInterval: 60 * 1000,
  });

  const humidityQuery = useGetMostRecentMeasurementQuery({
    variables: {
      locationSlug: props.slug,
      type: MeasurementType.Humidity,
    },
    onError: notification.error,
    pollInterval: 60 * 1000,
  });

  return (
    <Card {...cardProps}>
      <Row gutter={[12, 24]}>
        <Col xs={12} md={8}>
          <Statistic
            title="Temperature"
            value={temperatureQuery.data?.getMeasurements[0]?.value}
            suffix="°C"
            loading={temperatureQuery.loading}
            precision={1}
          />
          {temperatureQuery.data ? (
            <RelativeTimestamp
              timestamp={temperatureQuery.data.getMeasurements[0]?.createdDate}
            />
          ) : null}
        </Col>
        <Col xs={12} md={8}>
          <Statistic
            title="Humidity"
            value={humidityQuery.data?.getMeasurements[0]?.value}
            suffix="%"
            loading={humidityQuery.loading}
            precision={1}
          />
          {humidityQuery.data ? (
            <RelativeTimestamp
              timestamp={humidityQuery.data.getMeasurements[0]?.createdDate}
            />
          ) : null}
        </Col>
      </Row>
    </Card>
  );
};

export default LocationCard;
