import React, { useState } from 'react';
import { Layout, Menu, Button, Drawer, MenuProps, Row } from 'antd';
import { useCookies } from 'react-cookie';
import { LogoutOutlined } from '@ant-design/icons/lib/icons';
import { Link } from 'react-router-dom';
import ProfileLink from './profile-link';
const { Header: AntHeader } = Layout;

const menuItems = [
  {
    label: 'Dashboard',
    url: '/dashboard',
  },
  {
    label: 'Homes',
    url: '/homes',
  },
  {
    label: 'Actions',
    url: '/actions',
  },
  {
    label: 'Freerider',
    url: '/freerider',
  },
];

export default function Header() {
  const [, , removeCookie] = useCookies();

  const logout = () => {
    console.log('bye');
    removeCookie('idToken');
    removeCookie('refreshToken');
    window.location.href = '/login';
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen((isOpen) => !isOpen);

  const menuProps: MenuProps = {
    mode: 'vertical',
    items: menuItems.map((item) => {
      return {
        key: item.url,
        label: (
          <Link to={item.url} onClick={toggleMenu}>
            {item.label}
          </Link>
        ),
      };
    }),
  };

  return (
    <AntHeader
      style={{
        zIndex: 1,
        width: '100%',
        height: 'auto',
        background: '#2e3d5c',
      }}
    >
      <Row
        gutter={[{ xs: 16, md: 32 }, 16]}
        style={{ padding: '16px 0' }}
        justify="space-between"
        align="middle"
      >
        <Button className="menu-btn" type="primary" onClick={toggleMenu}>
          <span className="bars-btn"></span>
        </Button>
        <Drawer placement="left" onClose={toggleMenu} open={isMenuOpen}>
          <Menu {...menuProps} />
          <Menu
            items={[
              {
                key: 'logout',
                label: (
                  <Button type="link" onClick={logout} rootClassName="logout-btn">
                    <LogoutOutlined /> Logout
                  </Button>
                ),
              },
            ]}
          />
        </Drawer>
        <ProfileLink />
      </Row>
    </AntHeader>
  );
}
