import React from 'react';
import './App.css';
import { Layout } from 'antd';
import Header from './features/header/header';
import styled from 'styled-components/macro';
import breakpoints from './lib/breakpoints';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Login from './features/login';
import ProtectedRoute from './features/protected-route';
import Dashboard from './pages/dashboard';
import Home from './pages/home';
import Homes from './pages/homes';
import Location from './pages/location';
import CalendarPage from './pages/calendar';
import FreeriderPage from './pages/freerider';
import FreeriderRegisterInterestPage from './pages/freerider-register-interest';
import ProfilePage from './pages/profile';

const Content = styled(Layout.Content)`
  flex: 1;
  padding: 10px 5px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 50px;
    margin-top: 64px;
  }
`;

function App() {
  // check for new SW on navigate
  const location = useLocation();
  React.useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  }, [location]);

  return (
    <Layout style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Content>
        <Routes>
          <Route path="/" element={<Navigate to="/homes" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="/homes/:slug" element={<Home />} />
          <Route path="/homes/:homeSlug/:locationSlug" element={<Location />} />
          <Route
            path="/homes/:homeSlug/:locationSlug/calendar"
            element={<CalendarPage />}
          />
          <Route index path="/homes" element={<Homes />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/freerider" element={<FreeriderPage />} />
            <Route
              path="/freerider/register-interest"
              element={<FreeriderRegisterInterestPage />}
            />
            <Route path="/profile" element={<ProfilePage />} />
          </Route>
        </Routes>
      </Content>
      <Layout.Footer style={{ textAlign: 'center' }}>Kräftlund</Layout.Footer>
    </Layout>
  );
}

export default App;
