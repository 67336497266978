import { useParams } from 'react-router-dom';
import PageWrapper, { PageWrapperProps } from '../../features/page-wrapper';
import { useGetHomeQuery } from '../../graphql-operations';
import { App, Divider, Skeleton, Typography } from 'antd';
import LocationCard from './location-card';
import Actions from './actions';
import CameraCapturesOverview from './camera-captures-overview';

const Home = () => {
  const params = useParams();
  const { notification } = App.useApp();

  const slug = String(params.slug);

  const { loading, data } = useGetHomeQuery({
    variables: {
      slug,
    },
    onError: notification.error,
  });

  const pageWrapperProps: PageWrapperProps = {
    title: 'Home',
    breadcrumbs: [
      {
        label: 'Homes',
        url: '/homes',
      },
      {
        label: data?.getHome.name || 'loading...',
        url: `/home/${slug}`,
      },
    ],
  };

  return (
    <PageWrapper {...pageWrapperProps}>
      <Skeleton loading={loading} />
      <Typography.Title level={2}>{data?.getHome.name}</Typography.Title>
      <Divider />
      <Typography.Title level={4}>Locations</Typography.Title>
      {(data?.getHome.locations || []).map((location) => {
        return (
          <LocationCard slug={location.slug} title={location.name} key={location.slug} />
        );
      })}
      <Actions />
      <Divider />
      <CameraCapturesOverview />
    </PageWrapper>
  );
};

export default Home;
