import { Link } from 'react-router-dom';
import PageWrapper, { PageWrapperProps } from '../../features/page-wrapper';
import { App, Button, Divider, Skeleton, Typography } from 'antd';
import { useGetOwnFreeriderInterestsQuery } from '../../graphql-operations';
import FreeriderInterest from './freerider-interest';

const FreeriderPage = () => {
  const { notification } = App.useApp();
  const pageWrapperProps: PageWrapperProps = {
    title: 'Freerider',
    breadcrumbs: [
      {
        label: 'Home',
        url: '/dashboard',
      },
      {
        label: 'Freerider',
        url: '/freerider',
      },
    ],
    headerExtra: (
      <Link to="/freerider/register-interest">
        <Button type="primary">I want to go somewhere</Button>
      </Link>
    ),
  };

  const { data, loading } = useGetOwnFreeriderInterestsQuery({
    onError: notification.error,
  });

  return (
    <PageWrapper {...pageWrapperProps}>
      <Typography.Title level={3}>My Freerider Interests</Typography.Title>
      <Divider />
      <Skeleton loading={loading} />
      {data
        ? data.getOwnFreeriderInterests.map((interest) => {
            return <FreeriderInterest key={interest.id} {...interest} />;
          })
        : null}
    </PageWrapper>
  );
};

export default FreeriderPage;
