import {
  App,
  Button,
  Descriptions,
  Divider,
  Popconfirm,
  PopconfirmProps,
  Space,
  Typography,
} from 'antd';
import { DateTime } from 'luxon';
import { useRemoveFreeriderInterestMutation } from '../../graphql-operations';

export interface FreereiderInterestProps {
  from: string[];
  to: string[];
  after: string;
  before: string;
  id: string;
}

const FreeriderInterest = (props: FreereiderInterestProps) => {
  const { notification } = App.useApp();

  const [removeFreeriderInterest, { loading }] = useRemoveFreeriderInterestMutation({
    onError: notification.error,
    refetchQueries: ['getOwnFreeriderInterests'],
    awaitRefetchQueries: true,
    variables: {
      id: props.id,
    },
    onCompleted: () => {
      notification.success({ message: 'Interest removed' });
    },
  });

  const deletePopconfirmProps: PopconfirmProps = {
    title: 'Remove Freerider Interest?',
    description: 'Are you sure?',
    onConfirm: async () => {
      await removeFreeriderInterest();
    },
    okButtonProps: {
      loading,
    },
    okText: 'Remove',
  };

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Descriptions bordered size="small">
          <Descriptions.Item label="From">
            <Typography.Text>{props.from.join(', ')}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="To">
            <Typography.Text>{props.to.join(', ')}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Start">
            <Typography.Text>
              {DateTime.fromISO(props.after).toFormat('yyyy-MM-dd')}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="End">
            <Typography.Text>
              {DateTime.fromISO(props.before).toFormat('yyyy-MM-dd')}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
        <Popconfirm {...deletePopconfirmProps}>
          <Button danger type="primary">
            Remove
          </Button>
        </Popconfirm>
      </Space>
      <Divider />
    </>
  );
};

export default FreeriderInterest;
