import {
  ResponsiveContainer,
  LineChart as RechartsLineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  YAxisProps,
} from 'recharts';
import { FormattedMeasurement } from '../../lib/format-measurements';
import { MeasurementType } from '../../graphql-operations';
import { DateTime } from 'luxon';

export interface LineChartProps {
  data: FormattedMeasurement[];
}

const LineChart = (props: LineChartProps) => {
  const yAxisProps: YAxisProps = {
    width: 25,
    domain: ([min, max]) => {
      return [0, Math.ceil((max + 5) / 10) * 10];
    },
    allowDecimals: false,
    tickFormatter: (val: unknown) => {
      return String(parseInt(String(val)));
    },
  };

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight="500px">
      <RechartsLineChart
        data={props.data}
        width={500}
        height={300}
        margin={{
          left: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="timestamp"
          tickFormatter={(tick: number) => {
            return DateTime.fromMillis(tick).toFormat('HH:mm');
          }}
        />
        <YAxis {...yAxisProps} />
        <Tooltip
          labelFormatter={(time: number) => {
            return DateTime.fromMillis(time).toFormat('HH:mm');
          }}
          formatter={(value) => {
            return Number(value).toFixed(1);
          }}
        />
        <Legend />
        <Line type="monotone" dataKey={MeasurementType.Humidity} stroke="#82ca9d" />
        <Line type="monotone" dataKey={MeasurementType.Temperature} />
      </RechartsLineChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
