import { App, Button, ButtonProps, Input, InputProps, Space } from 'antd';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { useLoginMutation } from '../../graphql-operations';
import { Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from '../../lib/user-context';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { user } = useContext(UserContext);

  const { notification } = App.useApp();
  const navigate = useNavigate();

  const [login, mutationResult] = useLoginMutation({
    onError: notification.error,
  });

  if (user) {
    return <Navigate to={'/homes'} replace />;
  }

  const emailInputProps: InputProps = {
    type: 'email',
    value: email,
    onChange: (event) => {
      setEmail(event.target.value);
    },
    placeholder: 'Email',
  };

  const passwordInputProps: InputProps = {
    type: 'password',
    value: password,
    onChange: (event) => {
      setPassword(event.target.value);
    },
    placeholder: 'Password',
  };

  const loginButtonProps: ButtonProps = {
    type: 'primary',
    loading: mutationResult.loading,
    onClick: () => {
      login({
        variables: {
          email,
          password,
        },
        onCompleted: (data) => {
          if (data.login) {
            navigate('/dashboard');
          }
        },
        refetchQueries: ['getSelf'],
        awaitRefetchQueries: true,
      });
    },
  };

  return (
    <Wrapper>
      <Space direction="vertical" style={{ width: '100%', maxWidth: '300px' }}>
        <Input {...emailInputProps} />
        <Input {...passwordInputProps} />
        <Button {...loginButtonProps}>Log in</Button>
      </Space>
    </Wrapper>
  );
};

export default Login;
