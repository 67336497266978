import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DurationString: any;
  JSON: unknown;
};

export type CameraCapture = {
  __typename?: 'CameraCapture';
  createdDate: Scalars['Date'];
  id: Scalars['String'];
  path: Scalars['String'];
};

export type FreeriderInterest = {
  __typename?: 'FreeriderInterest';
  active: Scalars['Boolean'];
  after: Scalars['Date'];
  before: Scalars['Date'];
  from: Array<Scalars['String']>;
  id: Scalars['String'];
  to: Array<Scalars['String']>;
  userEmail: Scalars['String'];
};

export type Home = {
  __typename?: 'Home';
  id: Scalars['String'];
  locations: Array<Location>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  home: Home;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type LoginResult = {
  __typename?: 'LoginResult';
  expiresIn: Scalars['Int'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: User;
};

export type Measurement = {
  __typename?: 'Measurement';
  createdDate: Scalars['Date'];
  id: Scalars['String'];
  locationSlug: Scalars['String'];
  type: MeasurementType;
  value: Scalars['Float'];
};

export type MeasurementInput = {
  type: MeasurementType;
  value: Scalars['Float'];
};

export enum MeasurementType {
  Humidity = 'HUMIDITY',
  Temperature = 'TEMPERATURE'
}

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  capturePhotograph: CameraCapture;
  changePassword: Scalars['Boolean'];
  createFreeriderInterest: FreeriderInterest;
  createHome: Home;
  createLocation: Location;
  login: LoginResult;
  publishMessage: Scalars['Boolean'];
  registerPushSubscription?: Maybe<Scalars['Boolean']>;
  registerUser: User;
  removeFreeriderInterest: Scalars['Boolean'];
  reportMeasurements: Scalars['Int'];
};


export type MutationCapturePhotographArgs = {
  homeSlug: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationCreateFreeriderInterestArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
  from: Array<Scalars['String']>;
  to: Array<Scalars['String']>;
};


export type MutationCreateHomeArgs = {
  name: Scalars['String'];
};


export type MutationCreateLocationArgs = {
  homeId: Scalars['String'];
  name: Scalars['String'];
  secret: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationPublishMessageArgs = {
  homeSlug: Scalars['String'];
  message: Scalars['String'];
};


export type MutationRegisterPushSubscriptionArgs = {
  pushSubscription: PushSubscriptionInput;
};


export type MutationRegisterUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRemoveFreeriderInterestArgs = {
  id: Scalars['String'];
};


export type MutationReportMeasurementsArgs = {
  locationSlug: Scalars['String'];
  measurements: Array<MeasurementInput>;
  secret: Scalars['String'];
};

export type PushSubscriptionInput = {
  auth: Scalars['String'];
  endpoint: Scalars['String'];
  p256dh: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  getCameraCaptures: Array<CameraCapture>;
  getFreeriderStations: Array<Scalars['String']>;
  getHome: Home;
  getHomes: Array<Home>;
  getLocation: Location;
  getMeasurements: Array<Measurement>;
  getOwnFreeriderInterests: Array<FreeriderInterest>;
  getSelf?: Maybe<User>;
  getStatistics: Array<Statistic>;
};


export type QueryGetCameraCapturesArgs = {
  homeSlug: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryGetHomeArgs = {
  slug: Scalars['String'];
};


export type QueryGetLocationArgs = {
  slug: Scalars['String'];
};


export type QueryGetMeasurementsArgs = {
  after?: InputMaybe<Scalars['Date']>;
  before?: InputMaybe<Scalars['Date']>;
  locationSlugs: Array<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SortInput>>;
  take?: InputMaybe<Scalars['Int']>;
  types: Array<MeasurementType>;
};


export type QueryGetOwnFreeriderInterestsArgs = {
  past?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetStatisticsArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
  locationSlug: Scalars['String'];
  type: MeasurementType;
  unit: Statistics_Unit;
};

export enum Role_Name {
  Admin = 'ADMIN'
}

export enum Sort_Order {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Statistics_Unit {
  Day = 'day',
  Month = 'month'
}

export type SortInput = {
  order: Sort_Order;
  property: Scalars['String'];
};

export type Statistic = {
  __typename?: 'Statistic';
  average?: Maybe<Scalars['Float']>;
  date: Scalars['Date'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  type: MeasurementType;
  unit: Statistics_Unit;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  homes: Array<Home>;
  id: Scalars['String'];
  roles: Array<Role_Name>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResult', idToken: string, refreshToken: string, expiresIn: number, user: { __typename?: 'User', id: string, email: string } } };

export type GetSelfQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSelfQuery = { __typename?: 'Query', getSelf?: { __typename?: 'User', id: string, email: string, roles: Array<Role_Name> } | null };

export type RegisterPushSubscriptionMutationVariables = Exact<{
  input: PushSubscriptionInput;
}>;


export type RegisterPushSubscriptionMutation = { __typename?: 'Mutation', registerPushSubscription?: boolean | null };

export type GetStatisticsQueryVariables = Exact<{
  locationSlug: Scalars['String'];
  before: Scalars['Date'];
  after: Scalars['Date'];
  type: MeasurementType;
  unit: Statistics_Unit;
}>;


export type GetStatisticsQuery = { __typename?: 'Query', getStatistics: Array<{ __typename?: 'Statistic', date: string, min?: number | null, max?: number | null, average?: number | null, type: MeasurementType }> };

export type GetFreeriderStationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFreeriderStationsQuery = { __typename?: 'Query', getFreeriderStations: Array<string> };

export type CreateFreeriderInterestMutationVariables = Exact<{
  from: Array<Scalars['String']> | Scalars['String'];
  to: Array<Scalars['String']> | Scalars['String'];
  before: Scalars['Date'];
  after: Scalars['Date'];
}>;


export type CreateFreeriderInterestMutation = { __typename?: 'Mutation', createFreeriderInterest: { __typename?: 'FreeriderInterest', id: string, from: Array<string>, to: Array<string>, before: string, after: string } };

export type GetOwnFreeriderInterestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOwnFreeriderInterestsQuery = { __typename?: 'Query', getOwnFreeriderInterests: Array<{ __typename?: 'FreeriderInterest', id: string, from: Array<string>, to: Array<string>, after: string, before: string }> };

export type RemoveFreeriderInterestMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveFreeriderInterestMutation = { __typename?: 'Mutation', removeFreeriderInterest: boolean };

export type GetHomeQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetHomeQuery = { __typename?: 'Query', getHome: { __typename?: 'Home', id: string, name: string, slug: string, locations: Array<{ __typename?: 'Location', id: string, name: string, slug: string }> } };

export type GetMostRecentMeasurementQueryVariables = Exact<{
  locationSlug: Scalars['String'];
  type: MeasurementType;
}>;


export type GetMostRecentMeasurementQuery = { __typename?: 'Query', getMeasurements: Array<{ __typename?: 'Measurement', id: string, type: MeasurementType, value: number, createdDate: string }> };

export type PublishMessageMutationVariables = Exact<{
  homeSlug: Scalars['String'];
  message: Scalars['String'];
}>;


export type PublishMessageMutation = { __typename?: 'Mutation', publishMessage: boolean };

export type CapturePhotographMutationVariables = Exact<{
  homeSlug: Scalars['String'];
}>;


export type CapturePhotographMutation = { __typename?: 'Mutation', capturePhotograph: { __typename?: 'CameraCapture', id: string } };

export type GetCameraCapturesQueryVariables = Exact<{
  homeSlug: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type GetCameraCapturesQuery = { __typename?: 'Query', getCameraCaptures: Array<{ __typename?: 'CameraCapture', id: string, path: string, createdDate: string }> };

export type GetHomesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomesQuery = { __typename?: 'Query', getHomes: Array<{ __typename?: 'Home', id: string, name: string, slug: string }> };

export type GetLocationQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetLocationQuery = { __typename?: 'Query', getLocation: { __typename?: 'Location', id: string, name: string, slug: string, home: { __typename?: 'Home', id: string, name: string, slug: string } } };

export type GetMeasurementsQueryVariables = Exact<{
  locationSlugs: Array<Scalars['String']> | Scalars['String'];
  types: Array<MeasurementType> | MeasurementType;
  before: Scalars['Date'];
  after: Scalars['Date'];
}>;


export type GetMeasurementsQuery = { __typename?: 'Query', getMeasurements: Array<{ __typename?: 'Measurement', id: string, createdDate: string, type: MeasurementType, value: number }> };


export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    idToken
    refreshToken
    expiresIn
    user {
      id
      email
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetSelfDocument = gql`
    query getSelf {
  getSelf {
    id
    email
    roles
  }
}
    `;

/**
 * __useGetSelfQuery__
 *
 * To run a query within a React component, call `useGetSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
      }
export function useGetSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
        }
export type GetSelfQueryHookResult = ReturnType<typeof useGetSelfQuery>;
export type GetSelfLazyQueryHookResult = ReturnType<typeof useGetSelfLazyQuery>;
export type GetSelfQueryResult = Apollo.QueryResult<GetSelfQuery, GetSelfQueryVariables>;
export const RegisterPushSubscriptionDocument = gql`
    mutation registerPushSubscription($input: PushSubscriptionInput!) {
  registerPushSubscription(pushSubscription: $input)
}
    `;
export type RegisterPushSubscriptionMutationFn = Apollo.MutationFunction<RegisterPushSubscriptionMutation, RegisterPushSubscriptionMutationVariables>;

/**
 * __useRegisterPushSubscriptionMutation__
 *
 * To run a mutation, you first call `useRegisterPushSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPushSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPushSubscriptionMutation, { data, loading, error }] = useRegisterPushSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPushSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<RegisterPushSubscriptionMutation, RegisterPushSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterPushSubscriptionMutation, RegisterPushSubscriptionMutationVariables>(RegisterPushSubscriptionDocument, options);
      }
export type RegisterPushSubscriptionMutationHookResult = ReturnType<typeof useRegisterPushSubscriptionMutation>;
export type RegisterPushSubscriptionMutationResult = Apollo.MutationResult<RegisterPushSubscriptionMutation>;
export type RegisterPushSubscriptionMutationOptions = Apollo.BaseMutationOptions<RegisterPushSubscriptionMutation, RegisterPushSubscriptionMutationVariables>;
export const GetStatisticsDocument = gql`
    query getStatistics($locationSlug: String!, $before: Date!, $after: Date!, $type: MeasurementType!, $unit: STATISTICS_UNIT!) {
  getStatistics(
    locationSlug: $locationSlug
    before: $before
    after: $after
    type: $type
    unit: $unit
  ) {
    date
    min
    max
    average
    type
  }
}
    `;

/**
 * __useGetStatisticsQuery__
 *
 * To run a query within a React component, call `useGetStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticsQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      type: // value for 'type'
 *      unit: // value for 'unit'
 *   },
 * });
 */
export function useGetStatisticsQuery(baseOptions: Apollo.QueryHookOptions<GetStatisticsQuery, GetStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(GetStatisticsDocument, options);
      }
export function useGetStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatisticsQuery, GetStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(GetStatisticsDocument, options);
        }
export type GetStatisticsQueryHookResult = ReturnType<typeof useGetStatisticsQuery>;
export type GetStatisticsLazyQueryHookResult = ReturnType<typeof useGetStatisticsLazyQuery>;
export type GetStatisticsQueryResult = Apollo.QueryResult<GetStatisticsQuery, GetStatisticsQueryVariables>;
export const GetFreeriderStationsDocument = gql`
    query getFreeriderStations {
  getFreeriderStations
}
    `;

/**
 * __useGetFreeriderStationsQuery__
 *
 * To run a query within a React component, call `useGetFreeriderStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreeriderStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreeriderStationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFreeriderStationsQuery(baseOptions?: Apollo.QueryHookOptions<GetFreeriderStationsQuery, GetFreeriderStationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFreeriderStationsQuery, GetFreeriderStationsQueryVariables>(GetFreeriderStationsDocument, options);
      }
export function useGetFreeriderStationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFreeriderStationsQuery, GetFreeriderStationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFreeriderStationsQuery, GetFreeriderStationsQueryVariables>(GetFreeriderStationsDocument, options);
        }
export type GetFreeriderStationsQueryHookResult = ReturnType<typeof useGetFreeriderStationsQuery>;
export type GetFreeriderStationsLazyQueryHookResult = ReturnType<typeof useGetFreeriderStationsLazyQuery>;
export type GetFreeriderStationsQueryResult = Apollo.QueryResult<GetFreeriderStationsQuery, GetFreeriderStationsQueryVariables>;
export const CreateFreeriderInterestDocument = gql`
    mutation createFreeriderInterest($from: [String!]!, $to: [String!]!, $before: Date!, $after: Date!) {
  createFreeriderInterest(from: $from, to: $to, before: $before, after: $after) {
    id
    from
    to
    before
    after
  }
}
    `;
export type CreateFreeriderInterestMutationFn = Apollo.MutationFunction<CreateFreeriderInterestMutation, CreateFreeriderInterestMutationVariables>;

/**
 * __useCreateFreeriderInterestMutation__
 *
 * To run a mutation, you first call `useCreateFreeriderInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFreeriderInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFreeriderInterestMutation, { data, loading, error }] = useCreateFreeriderInterestMutation({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCreateFreeriderInterestMutation(baseOptions?: Apollo.MutationHookOptions<CreateFreeriderInterestMutation, CreateFreeriderInterestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFreeriderInterestMutation, CreateFreeriderInterestMutationVariables>(CreateFreeriderInterestDocument, options);
      }
export type CreateFreeriderInterestMutationHookResult = ReturnType<typeof useCreateFreeriderInterestMutation>;
export type CreateFreeriderInterestMutationResult = Apollo.MutationResult<CreateFreeriderInterestMutation>;
export type CreateFreeriderInterestMutationOptions = Apollo.BaseMutationOptions<CreateFreeriderInterestMutation, CreateFreeriderInterestMutationVariables>;
export const GetOwnFreeriderInterestsDocument = gql`
    query getOwnFreeriderInterests {
  getOwnFreeriderInterests {
    id
    from
    to
    after
    before
  }
}
    `;

/**
 * __useGetOwnFreeriderInterestsQuery__
 *
 * To run a query within a React component, call `useGetOwnFreeriderInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnFreeriderInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnFreeriderInterestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOwnFreeriderInterestsQuery(baseOptions?: Apollo.QueryHookOptions<GetOwnFreeriderInterestsQuery, GetOwnFreeriderInterestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnFreeriderInterestsQuery, GetOwnFreeriderInterestsQueryVariables>(GetOwnFreeriderInterestsDocument, options);
      }
export function useGetOwnFreeriderInterestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnFreeriderInterestsQuery, GetOwnFreeriderInterestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnFreeriderInterestsQuery, GetOwnFreeriderInterestsQueryVariables>(GetOwnFreeriderInterestsDocument, options);
        }
export type GetOwnFreeriderInterestsQueryHookResult = ReturnType<typeof useGetOwnFreeriderInterestsQuery>;
export type GetOwnFreeriderInterestsLazyQueryHookResult = ReturnType<typeof useGetOwnFreeriderInterestsLazyQuery>;
export type GetOwnFreeriderInterestsQueryResult = Apollo.QueryResult<GetOwnFreeriderInterestsQuery, GetOwnFreeriderInterestsQueryVariables>;
export const RemoveFreeriderInterestDocument = gql`
    mutation removeFreeriderInterest($id: String!) {
  removeFreeriderInterest(id: $id)
}
    `;
export type RemoveFreeriderInterestMutationFn = Apollo.MutationFunction<RemoveFreeriderInterestMutation, RemoveFreeriderInterestMutationVariables>;

/**
 * __useRemoveFreeriderInterestMutation__
 *
 * To run a mutation, you first call `useRemoveFreeriderInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFreeriderInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFreeriderInterestMutation, { data, loading, error }] = useRemoveFreeriderInterestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFreeriderInterestMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFreeriderInterestMutation, RemoveFreeriderInterestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFreeriderInterestMutation, RemoveFreeriderInterestMutationVariables>(RemoveFreeriderInterestDocument, options);
      }
export type RemoveFreeriderInterestMutationHookResult = ReturnType<typeof useRemoveFreeriderInterestMutation>;
export type RemoveFreeriderInterestMutationResult = Apollo.MutationResult<RemoveFreeriderInterestMutation>;
export type RemoveFreeriderInterestMutationOptions = Apollo.BaseMutationOptions<RemoveFreeriderInterestMutation, RemoveFreeriderInterestMutationVariables>;
export const GetHomeDocument = gql`
    query getHome($slug: String!) {
  getHome(slug: $slug) {
    id
    name
    slug
    locations {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useGetHomeQuery__
 *
 * To run a query within a React component, call `useGetHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetHomeQuery(baseOptions: Apollo.QueryHookOptions<GetHomeQuery, GetHomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHomeQuery, GetHomeQueryVariables>(GetHomeDocument, options);
      }
export function useGetHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomeQuery, GetHomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHomeQuery, GetHomeQueryVariables>(GetHomeDocument, options);
        }
export type GetHomeQueryHookResult = ReturnType<typeof useGetHomeQuery>;
export type GetHomeLazyQueryHookResult = ReturnType<typeof useGetHomeLazyQuery>;
export type GetHomeQueryResult = Apollo.QueryResult<GetHomeQuery, GetHomeQueryVariables>;
export const GetMostRecentMeasurementDocument = gql`
    query getMostRecentMeasurement($locationSlug: String!, $type: MeasurementType!) {
  getMeasurements(
    locationSlugs: [$locationSlug]
    types: [$type]
    take: 1
    sort: [{property: "createdDate", order: desc}]
  ) {
    id
    type
    value
    createdDate
  }
}
    `;

/**
 * __useGetMostRecentMeasurementQuery__
 *
 * To run a query within a React component, call `useGetMostRecentMeasurementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentMeasurementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentMeasurementQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetMostRecentMeasurementQuery(baseOptions: Apollo.QueryHookOptions<GetMostRecentMeasurementQuery, GetMostRecentMeasurementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMostRecentMeasurementQuery, GetMostRecentMeasurementQueryVariables>(GetMostRecentMeasurementDocument, options);
      }
export function useGetMostRecentMeasurementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMostRecentMeasurementQuery, GetMostRecentMeasurementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMostRecentMeasurementQuery, GetMostRecentMeasurementQueryVariables>(GetMostRecentMeasurementDocument, options);
        }
export type GetMostRecentMeasurementQueryHookResult = ReturnType<typeof useGetMostRecentMeasurementQuery>;
export type GetMostRecentMeasurementLazyQueryHookResult = ReturnType<typeof useGetMostRecentMeasurementLazyQuery>;
export type GetMostRecentMeasurementQueryResult = Apollo.QueryResult<GetMostRecentMeasurementQuery, GetMostRecentMeasurementQueryVariables>;
export const PublishMessageDocument = gql`
    mutation publishMessage($homeSlug: String!, $message: String!) {
  publishMessage(homeSlug: $homeSlug, message: $message)
}
    `;
export type PublishMessageMutationFn = Apollo.MutationFunction<PublishMessageMutation, PublishMessageMutationVariables>;

/**
 * __usePublishMessageMutation__
 *
 * To run a mutation, you first call `usePublishMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMessageMutation, { data, loading, error }] = usePublishMessageMutation({
 *   variables: {
 *      homeSlug: // value for 'homeSlug'
 *      message: // value for 'message'
 *   },
 * });
 */
export function usePublishMessageMutation(baseOptions?: Apollo.MutationHookOptions<PublishMessageMutation, PublishMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishMessageMutation, PublishMessageMutationVariables>(PublishMessageDocument, options);
      }
export type PublishMessageMutationHookResult = ReturnType<typeof usePublishMessageMutation>;
export type PublishMessageMutationResult = Apollo.MutationResult<PublishMessageMutation>;
export type PublishMessageMutationOptions = Apollo.BaseMutationOptions<PublishMessageMutation, PublishMessageMutationVariables>;
export const CapturePhotographDocument = gql`
    mutation capturePhotograph($homeSlug: String!) {
  capturePhotograph(homeSlug: $homeSlug) {
    id
  }
}
    `;
export type CapturePhotographMutationFn = Apollo.MutationFunction<CapturePhotographMutation, CapturePhotographMutationVariables>;

/**
 * __useCapturePhotographMutation__
 *
 * To run a mutation, you first call `useCapturePhotographMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCapturePhotographMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [capturePhotographMutation, { data, loading, error }] = useCapturePhotographMutation({
 *   variables: {
 *      homeSlug: // value for 'homeSlug'
 *   },
 * });
 */
export function useCapturePhotographMutation(baseOptions?: Apollo.MutationHookOptions<CapturePhotographMutation, CapturePhotographMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CapturePhotographMutation, CapturePhotographMutationVariables>(CapturePhotographDocument, options);
      }
export type CapturePhotographMutationHookResult = ReturnType<typeof useCapturePhotographMutation>;
export type CapturePhotographMutationResult = Apollo.MutationResult<CapturePhotographMutation>;
export type CapturePhotographMutationOptions = Apollo.BaseMutationOptions<CapturePhotographMutation, CapturePhotographMutationVariables>;
export const GetCameraCapturesDocument = gql`
    query getCameraCaptures($homeSlug: String!, $skip: Int, $take: Int) {
  getCameraCaptures(homeSlug: $homeSlug, skip: $skip, take: $take) {
    id
    path
    createdDate
  }
}
    `;

/**
 * __useGetCameraCapturesQuery__
 *
 * To run a query within a React component, call `useGetCameraCapturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCameraCapturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCameraCapturesQuery({
 *   variables: {
 *      homeSlug: // value for 'homeSlug'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetCameraCapturesQuery(baseOptions: Apollo.QueryHookOptions<GetCameraCapturesQuery, GetCameraCapturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCameraCapturesQuery, GetCameraCapturesQueryVariables>(GetCameraCapturesDocument, options);
      }
export function useGetCameraCapturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCameraCapturesQuery, GetCameraCapturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCameraCapturesQuery, GetCameraCapturesQueryVariables>(GetCameraCapturesDocument, options);
        }
export type GetCameraCapturesQueryHookResult = ReturnType<typeof useGetCameraCapturesQuery>;
export type GetCameraCapturesLazyQueryHookResult = ReturnType<typeof useGetCameraCapturesLazyQuery>;
export type GetCameraCapturesQueryResult = Apollo.QueryResult<GetCameraCapturesQuery, GetCameraCapturesQueryVariables>;
export const GetHomesDocument = gql`
    query getHomes {
  getHomes {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetHomesQuery__
 *
 * To run a query within a React component, call `useGetHomesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomesQuery(baseOptions?: Apollo.QueryHookOptions<GetHomesQuery, GetHomesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHomesQuery, GetHomesQueryVariables>(GetHomesDocument, options);
      }
export function useGetHomesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomesQuery, GetHomesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHomesQuery, GetHomesQueryVariables>(GetHomesDocument, options);
        }
export type GetHomesQueryHookResult = ReturnType<typeof useGetHomesQuery>;
export type GetHomesLazyQueryHookResult = ReturnType<typeof useGetHomesLazyQuery>;
export type GetHomesQueryResult = Apollo.QueryResult<GetHomesQuery, GetHomesQueryVariables>;
export const GetLocationDocument = gql`
    query getLocation($slug: String!) {
  getLocation(slug: $slug) {
    id
    name
    slug
    home {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
      }
export function useGetLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export const GetMeasurementsDocument = gql`
    query getMeasurements($locationSlugs: [String!]!, $types: [MeasurementType!]!, $before: Date!, $after: Date!) {
  getMeasurements(
    locationSlugs: $locationSlugs
    types: $types
    before: $before
    after: $after
    sort: [{property: "createdDate", order: asc}]
  ) {
    id
    createdDate
    type
    value
  }
}
    `;

/**
 * __useGetMeasurementsQuery__
 *
 * To run a query within a React component, call `useGetMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasurementsQuery({
 *   variables: {
 *      locationSlugs: // value for 'locationSlugs'
 *      types: // value for 'types'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetMeasurementsQuery(baseOptions: Apollo.QueryHookOptions<GetMeasurementsQuery, GetMeasurementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeasurementsQuery, GetMeasurementsQueryVariables>(GetMeasurementsDocument, options);
      }
export function useGetMeasurementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeasurementsQuery, GetMeasurementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeasurementsQuery, GetMeasurementsQueryVariables>(GetMeasurementsDocument, options);
        }
export type GetMeasurementsQueryHookResult = ReturnType<typeof useGetMeasurementsQuery>;
export type GetMeasurementsLazyQueryHookResult = ReturnType<typeof useGetMeasurementsLazyQuery>;
export type GetMeasurementsQueryResult = Apollo.QueryResult<GetMeasurementsQuery, GetMeasurementsQueryVariables>;