import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../../lib/user-context';
import { Spin } from 'antd';

type UserType = {
  id: string;
};

export interface ProtectedRouteProps {
  children?: JSX.Element;
  user?: UserType;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { user, loading } = useContext(UserContext);
  if (loading) {
    return <Spin />;
  }
  if (!user) {
    return <Navigate to={'/login'} replace />;
  }
  return props.children ? props.children : <Outlet />;
};

export default ProtectedRoute;
