import PageWrapper, { PageWrapperProps } from '../../features/page-wrapper';

const ProfilePage = () => {
  const pageWrapperProps: PageWrapperProps = {
    title: 'My Profile',
    breadcrumbs: [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'Profile',
        url: '/profile',
      },
    ],
  };

  return <PageWrapper {...pageWrapperProps}></PageWrapper>;
};

export default ProfilePage;
