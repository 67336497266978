import { Typography } from 'antd';
import { GetStatisticsQuery } from '../../graphql-operations';
import React from 'react';

interface StatisticsProps {
  stats: GetStatisticsQuery['getStatistics'][0];
}

const getMaxType = (value: number) => {
  if (value < 23) return 'success';
  if (value < 24) return undefined;
  if (value < 26) return 'warning';
  return 'danger';
};

const getMinType = (value: number) => {
  if (value < 20) return 'success';
  if (value < 22) return undefined;
  if (value < 23) return 'warning';
  return 'danger';
};

const Statistics = (props: StatisticsProps) => {
  const Min = props.stats.min ? (
    <Typography.Text
      type={getMinType(props.stats.min)}
      style={{ display: 'block', whiteSpace: 'nowrap', fontSize: '12px' }}
    >
      {props.stats.min.toFixed(1)}
    </Typography.Text>
  ) : null;
  const Max = props.stats.max ? (
    <Typography.Text
      type={getMaxType(props.stats.max)}
      style={{ display: 'block', whiteSpace: 'nowrap', fontSize: '12px' }}
    >
      {props.stats.max.toFixed(1)}
    </Typography.Text>
  ) : null;
  const Avg = props.stats.average ? (
    <Typography.Text type="secondary" style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>
      {props.stats.average.toFixed(1)}
    </Typography.Text>
  ) : null;

  return (
    <React.Fragment>
      {Min}
      {Max}
      {Avg}
    </React.Fragment>
  );
};

export default Statistics;
