import React from 'react';
import { PageHeader, PageHeaderProps } from '@ant-design/pro-layout';
import { Breadcrumb, BreadcrumbProps, Layout, Space } from 'antd';
import { Link } from 'react-router-dom';

export interface PageWrapperProps {
  title: string | React.ReactNode;
  breadcrumbs: {
    url?: string;
    label: string;
  }[];
  headerExtra?: React.ReactNode;
  children?: React.ReactNode;
  subTitle?: React.ReactNode | string;
  style?: React.CSSProperties;
}

const defaultPageStyles = { background: 'white', padding: '8px' };

const PageWrapper = (props: PageWrapperProps) => {
  const pageHeaderProps: PageHeaderProps = {
    title: props.title,
    onBack: () => window.history.back(),
    ghost: false,
    extra: props.headerExtra,
    subTitle: props.subTitle,
  };

  const breadcrumbsProps: BreadcrumbProps = {
    items: props.breadcrumbs.map((crumb) => {
      return {
        title: crumb.url ? <Link to={crumb.url}>{crumb.label}</Link> : crumb.label,
      };
    }),
  };

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Breadcrumb {...breadcrumbsProps} />
      <PageHeader {...pageHeaderProps} />
      <Layout.Content style={{ ...defaultPageStyles, ...props.style }}>
        {props.children}
      </Layout.Content>
    </Space>
  );
};

export default PageWrapper;
