import { App, Button, ButtonProps, Row, Space, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import {
  useCapturePhotographMutation,
  usePublishMessageMutation,
} from '../../graphql-operations';

const Actions = () => {
  const { notification } = App.useApp();

  const params = useParams();
  const { slug } = params;

  const [publishMessage, { loading }] = usePublishMessageMutation({
    onCompleted: () => notification.success({ message: 'Triggered' }),
    onError: notification.error,
  });

  const [capturePhotograph, { loading: captureLoading }] = useCapturePhotographMutation({
    onCompleted: () => notification.success({ message: 'Photograph captured' }),
    onError: notification.error,
    refetchQueries: ['getCameraCaptures'],
  });

  const waterButtonProps: ButtonProps = {
    onClick: () => {
      publishMessage({
        variables: {
          homeSlug: String(slug),
          message: 'water',
        },
      });
    },
    type: 'primary',
    loading,
  };

  const capturePhotographButtonProps: ButtonProps = {
    onClick: () => {
      capturePhotograph({
        variables: {
          homeSlug: String(slug),
        },
      });
    },
    type: 'primary',
    loading: captureLoading,
  };

  return (
    <div>
      <Typography.Title level={3}>Actions</Typography.Title>
      <Row>
        <Space>
          <Button {...waterButtonProps}>Water</Button>
          <Button {...capturePhotographButtonProps}>Capture Photograph</Button>
        </Space>
      </Row>
    </div>
  );
};

export default Actions;
