import PageWrapper, { PageWrapperProps } from '../../features/page-wrapper';
import { App, List, ListProps, Typography } from 'antd';
import { GetHomesQuery, useGetHomesQuery } from '../../graphql-operations';
import { Link } from 'react-router-dom';

const Homes = () => {
  const pageWrapperProps: PageWrapperProps = {
    title: 'Homes',
    breadcrumbs: [
      {
        label: 'Homes',
        url: '/homes',
      },
    ],
  };

  const { notification } = App.useApp();

  const { loading, data } = useGetHomesQuery({
    onError: notification.error,
  });

  const listProps: ListProps<NonNullable<GetHomesQuery['getHomes']>[0]> = {
    header: <Typography.Title level={4}>Homes</Typography.Title>,
    dataSource: data?.getHomes || [],
    loading,
    renderItem: (item) => {
      return (
        <List.Item>
          <Link to={`/homes/${item.slug}`}>{item.name}</Link>
        </List.Item>
      );
    },
  };

  return (
    <PageWrapper {...pageWrapperProps}>
      <List {...listProps} />
    </PageWrapper>
  );
};

export default Homes;
