import { useContext } from 'react';
import { UserContext } from '../../lib/user-context';
import { Link } from 'react-router-dom';

const linkStyle = {
  lineHeight: '16px',
};

const ProfileLink = () => {
  const userContext = useContext(UserContext);

  if (!userContext.user) {
    return (
      <Link to="/login" style={linkStyle}>
        Log in
      </Link>
    );
  } else {
    return (
      <Link to="/profile" style={linkStyle}>
        {userContext.user.email}
      </Link>
    );
  }
};

export default ProfileLink;
