import { Link } from 'react-router-dom';
import PageWrapper, { PageWrapperProps } from '../../features/page-wrapper';
import { Row, Space } from 'antd';

const Dashboard = () => {
  const pageWrapperProps: PageWrapperProps = {
    title: 'Dashboard',
    breadcrumbs: [
      {
        url: '/dashboard',
        label: '/dashboard',
      },
    ],
  };
  return (
    <PageWrapper {...pageWrapperProps}>
      <Space direction="vertical">
        <Row>
          <Link to="/homes">Homes</Link>
        </Row>
        <Row>
          <Link to="/actions">Actions</Link>
        </Row>
        <Row>
          <Link to="/freerider">Hertz Freerider</Link>
        </Row>
      </Space>
    </PageWrapper>
  );
};

export default Dashboard;
