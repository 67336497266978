import { useParams } from 'react-router-dom';
import PageWrapper, { PageWrapperProps } from '../../features/page-wrapper';
import Calendar from './calendar';

const CalendarPage = () => {
  const params = useParams();

  const locationName = params.locationSlug || 'Location';

  const pageWrapperProps: PageWrapperProps = {
    title: `${locationName} - calendar`,
    breadcrumbs: [
      {
        label: 'Homes',
        url: '/homes',
      },
      {
        label: params.homeSlug || 'Home',
        url: `/homes/${params.homeSlug}`,
      },
      {
        label: locationName,
        url: `/homes/${params.homeSlug}/${params.locationSlug}`,
      },
      {
        label: 'Calendar',
        url: `/homes/${params.homeSlug}/${params.locationSlug}/calendar`,
      },
    ],
  };

  return (
    <PageWrapper {...pageWrapperProps}>
      <Calendar />
    </PageWrapper>
  );
};

export default CalendarPage;
