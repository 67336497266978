import { Col, Image, Row, Skeleton, Typography } from 'antd';
import { useGetCameraCapturesQuery } from '../../graphql-operations';
import { useParams } from 'react-router-dom';
import { IMAGE_HOST } from '../../lib/config';
import RelativeTimestamp from '../../components/relative-timestamp';

const CameraCapturesOverview = () => {
  const { slug } = useParams();

  const { loading, data } = useGetCameraCapturesQuery({
    variables: {
      homeSlug: String(slug),
      skip: 0,
      take: 3,
    },
  });

  return (
    <div>
      <Typography.Title level={3}>Camera Captures</Typography.Title>
      <Skeleton loading={loading} />
      <Row gutter={16}>
        {data?.getCameraCaptures.map((capture) => {
          return (
            <Col xs={8} key={capture.id}>
              <Image src={`${IMAGE_HOST}${capture.path}`} />
              <RelativeTimestamp timestamp={capture.createdDate} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default CameraCapturesOverview;
