import { Link, useParams } from 'react-router-dom';
import PageWrapper, { PageWrapperProps } from '../../features/page-wrapper';
import {
  MeasurementType,
  useGetLocationQuery,
  useGetMeasurementsQuery,
} from '../../graphql-operations';
import { Card, Col, Row, Skeleton, Statistic, Typography } from 'antd';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import RelativeTimestamp from '../../components/relative-timestamp';
import LineChart, { LineChartProps } from '../../features/line-chart';
import formatMeasurements from '../../lib/format-measurements';
import { CalendarOutlined } from '@ant-design/icons';

const Location = () => {
  const params = useParams();
  const [before, setBefore] = useState(DateTime.now());

  const { data, loading } = useGetLocationQuery({
    variables: {
      slug: String(params.locationSlug),
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setBefore(DateTime.now());
    }, 10 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const measurementsQuery = useGetMeasurementsQuery({
    variables: {
      locationSlugs: [String(params.locationSlug)],
      types: [MeasurementType.Humidity, MeasurementType.Temperature],
      before: String(before.toISO()),
      after: String(before.minus({ hours: 12 }).toISO()),
    },
  });

  const locationName = data?.getLocation.name || params.locationSlug || 'Location';

  const pageWrapperProps: PageWrapperProps = {
    title: locationName,
    breadcrumbs: [
      {
        label: 'Homes',
        url: '/homes',
      },
      {
        label: data?.getLocation.home.name || params.homeSlug || 'Home',
        url: `/homes/${params.homeSlug}`,
      },
      {
        label: locationName,
        url: `/homes/${params.homeSlug}/${params.locationSlug}`,
      },
    ],
  };

  const allMeasurements = measurementsQuery.data?.getMeasurements || [];

  const allTemperatureMeasurements = allMeasurements.filter((measurement) => {
    return measurement.type === MeasurementType.Temperature;
  });

  const mostRecentTemperatureMeasurement =
    allTemperatureMeasurements[allTemperatureMeasurements.length - 1];

  const allHumidityMeasurements = allMeasurements.filter((measurement) => {
    return measurement.type === MeasurementType.Humidity;
  });

  const mostRecentHumidityMeasurement =
    allHumidityMeasurements[allHumidityMeasurements.length - 1];

  const lineChartProps: LineChartProps = {
    data: formatMeasurements(allMeasurements, { minute: 30 }),
  };

  return (
    <PageWrapper {...pageWrapperProps}>
      <Skeleton loading={loading} />
      <Card title="At a glance">
        <Row>
          <Col xs={12} md={8}>
            <Statistic
              title="Temperature"
              value={mostRecentTemperatureMeasurement?.value}
              suffix="°C"
              loading={!mostRecentTemperatureMeasurement}
              precision={1}
            />
            {mostRecentTemperatureMeasurement ? (
              <RelativeTimestamp
                timestamp={mostRecentTemperatureMeasurement.createdDate}
              />
            ) : null}
          </Col>
          <Col xs={12} md={8}>
            <Statistic
              title="Humidity"
              value={mostRecentHumidityMeasurement?.value}
              suffix="%"
              loading={!mostRecentHumidityMeasurement}
              precision={1}
            />
            {mostRecentHumidityMeasurement ? (
              <RelativeTimestamp timestamp={mostRecentHumidityMeasurement.createdDate} />
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col xs={12} md={8}>
            <Link to={`/homes/${params.homeSlug}/${params.locationSlug}/calendar`}>
              <CalendarOutlined style={{ fontSize: '32px' }} />
              <Typography.Link style={{ textAlign: 'center' }}>
                Calendar view
              </Typography.Link>
            </Link>
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <LineChart {...lineChartProps} />
        </Row>
      </Card>
    </PageWrapper>
  );
};

export default Location;
