import { DateTime, DurationLike, Interval } from 'luxon';
import { GetMeasurementsQuery, MeasurementType } from '../graphql-operations';

export interface FormattedMeasurement extends Partial<Record<MeasurementType, number>> {
  timestamp: number;
}

const formatMeasurements = (
  measurements: GetMeasurementsQuery['getMeasurements'],
  increment: DurationLike
) => {
  if (!measurements.length) return [];
  const start = DateTime.fromISO(measurements[0].createdDate);
  const end = DateTime.fromISO(measurements[measurements.length - 1].createdDate);

  const interval = Interval.fromDateTimes(start, end);

  const units = interval.splitBy(increment);

  const types = [...new Set(measurements.map((m) => m.type))];

  const formatted = units.map((interval) => {
    const startTimeISO = interval.start?.toISO();
    if (!startTimeISO) {
      throw new Error('interval has no start');
    }
    const dataPoint: FormattedMeasurement = {
      timestamp: new Date(startTimeISO).getTime(),
    };

    types.forEach((measurementType) => {
      const measurement = measurements.find((measurement) => {
        return (
          measurement.type === measurementType &&
          DateTime.fromISO(measurement.createdDate) >= DateTime.fromISO(startTimeISO)
        );
      });
      if (measurement) {
        dataPoint[measurementType] = measurement.value;
      }
    });

    return dataPoint;
  });

  return formatted;
};

export default formatMeasurements;
