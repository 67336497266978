import { App, Button, DatePicker, Form, FormProps, Select, Typography } from 'antd';
import PageWrapper, { PageWrapperProps } from '../../features/page-wrapper';
import {
  useCreateFreeriderInterestMutation,
  useGetFreeriderStationsQuery,
} from '../../graphql-operations';
import { useNavigate } from 'react-router-dom';

const FreeriderRegisterInterestPage = () => {
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const pageWrapperProps: PageWrapperProps = {
    title: 'Freerider - Register Interest',
    breadcrumbs: [
      {
        label: 'Home',
        url: '/dashboard',
      },
      {
        label: 'Freerider',
        url: '/freerider',
      },
      {
        label: 'Register Interest',
        url: '/freerider/register-interest',
      },
    ],
  };

  const { loading, data } = useGetFreeriderStationsQuery({
    onError: notification.error,
  });

  const stationOptions =
    data?.getFreeriderStations.map((station) => {
      return {
        label: station,
        value: station,
      };
    }) || [];

  const [createFreeriderInterest, { loading: createLoading }] =
    useCreateFreeriderInterestMutation({
      onError: notification.error,
      onCompleted: () => {
        notification.success({ message: 'Interest registered. Good luck!' });
        navigate('/freerider');
      },
      refetchQueries: ['getOwnFreeriderInterests'],
    });

  const formProps: FormProps = {
    onFinish: (values) => {
      createFreeriderInterest({
        variables: {
          from: values.from,
          to: values.to,
          before: values.before,
          after: values.after,
        },
      });
    },
  };

  return (
    <PageWrapper {...pageWrapperProps}>
      <Typography.Title level={3}>Where do you want to go?</Typography.Title>
      <Form {...formProps}>
        <Form.Item name="from" label="From" required>
          <Select loading={loading} options={stationOptions} mode="multiple" />
        </Form.Item>
        <Form.Item name="to" label="To" required>
          <Select loading={loading} options={stationOptions} mode="multiple" />
        </Form.Item>
        <Form.Item name="after" label="On or after" required>
          <DatePicker disabledDate={(date) => date.isBefore(new Date(), 'day')} />
        </Form.Item>
        <Form.Item name="before" label="On or before" required>
          <DatePicker disabledDate={(date) => date.isBefore(new Date(), 'day')} />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={createLoading}>
          Register interest
        </Button>
      </Form>
    </PageWrapper>
  );
};

export default FreeriderRegisterInterestPage;
